

(function ($) {


    triggerED = function(href,target) {

        $('.overlay_container.exit_dis .overlay .link_a').attr('href', href);
        $('.overlay_container.exit_dis .overlay .link_span').html(href);
        $('.overlay_container.exit_dis').fadeIn();
        $('.overlay_container.exit_dis .overlay').addClass('opened');

    };


    $(document).on('click', 'a[href]', function (e) {

        var href = $(this).attr('href');
        var target = $(this).attr('target');

        if (isExitDisclaimberLink($(this))) {

            triggerED(href,target);
            e.preventDefault();

        }

    });

    $(document).on("click", ".link_a", function () {
        $('.overlay_container').fadeOut('fast');
        window.open($(this).attr('href'), '_blank');
    });

    $(document).on('click', '.close_overlay, .x, .link_a', function () {
        $('.overlay_container').fadeOut('fast');
        $('.overlay').removeClass('opened');
        return false;
    });




})(jQuery); // Fully reference jQuery after this point.